import Icons from "icons/sidebar";

const SideBarMenus = [
  {
    title: "User List",
    Icon: Icons.UserProfileIcon,
    path: "/dashboard/user-list",
    hidden: false
  },
  {
    title: "Item List",
    Icon: Icons.ItemManagementIcon,
    path: "/dashboard/item-list",
    hidden: false
  },
  {
    title: "Add admin",
    Icon: Icons.AddUserIcon,
    path: "/dashboard/add-admin",
    hidden: true
  },
];

export default SideBarMenus;
