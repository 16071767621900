import { AuthConsumer } from "contexts/AuthContext";
import Login from "pages/authentication/Login";
import { Fragment, ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { selectCredentialInfo } from "redux/reducer";

// component props interface
interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const authConsumer  = AuthConsumer();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  const currentAccessToken = localStorage.getItem('accessToken');
  if (currentAccessToken) {
    const currentRefreshToken = localStorage.getItem('refreshToken');
    authConsumer?.refreshInvalidToken({accessToken: currentAccessToken, refreshToken: currentRefreshToken ? currentRefreshToken : undefined})
  }

  const userCredential = useSelector(selectCredentialInfo);
  if (!userCredential) {
    return <Login />;
  }

  if (pathname !== requestedLocation) {
    setRequestedLocation(pathname);
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  return <Fragment>{children}</Fragment>;
};

export default AuthGuard;
