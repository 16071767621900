import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, FormHelperText } from "@mui/material";
import { TextFieldWrapper } from "components/authentication/StyledComponents";
import FlexBox from "components/FlexBox";
import LightTextField from "components/LightTextField";
import { H1, Paragraph } from "components/Typography";
import { AuthConsumer } from "contexts/AuthContext";
import { useFormik } from "formik";
import { RefreshTokenResponse } from "models/Response.model";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { authService } from "services/AuthService";
import * as Yup from "yup";

const Login: FC = () => {
  const authConsumer = AuthConsumer();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const initialValues = {
    username: "",
    password: "",
    submit: null,
  };

  // form field value validation schema
  const validationSchema = Yup.object().shape({
    username: Yup.string().max(255).required("Username is required"),
    password: Yup.string()
      .required("Password is required"),
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values: any) => {
        setLoading(true);
        authService
          .login({ username: values.username, password: values.password })
          .then((res) => {
            const data = res as unknown as RefreshTokenResponse;
            authConsumer?.saveToken(data);
            toast.success("You Logged In Successfully test");
            navigate("/dashboard");
          })
          .catch((error) => {
            setError(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });



  return (
    <FlexBox
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: { sm: "100%" },
      }}
    >
      <Card sx={{ padding: 4, maxWidth: 600, boxShadow: 20 }}>
        <FlexBox
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
        >
          <Box width={250} mb={1}>
            <img
              src="/static/logo/logo.svg"
              width="100%"
              alt="Share Right Logo"
            />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            Sign In
          </H1>
        </FlexBox>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
          <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
            <FlexBox justifyContent="space-between" flexWrap="wrap">
              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  Username
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="username"
                  type="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username || ""}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />
              </TextFieldWrapper>

              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  Password
                </Paragraph>
                <LightTextField
                  fullWidth
                  name="password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password || ""}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </TextFieldWrapper>
            </FlexBox>
            {error && (
              <FormHelperText
                error
                sx={{
                  mt: 2,
                  fontSize: 13,
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                {error}
              </FormHelperText>
            )}

            <Box sx={{ mt: 4 }}>
              {loading ? (
                <LoadingButton loading fullWidth variant="contained">
                  Sign In
                </LoadingButton>
              ) : (
                <Button fullWidth type="submit" variant="contained">
                  Sign In
                </Button>
              )}
            </Box>
          </form>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default Login;
