export const LOCAL_STORAGE_KEYS = {
    AccessToken: 'access_token',
    RefreshToken: 'refresh_token'
}

// ShareRight role
export enum SHARE_RIGHT_ROLE {
    User = 'ROLE_USER', // Renter
    Owner = 'ROLE_OWNER',
}

export enum TOKEN_STATUS  {
    EXPIRED,
    INVALID,
    VALID
 }

 export enum ERROR_CONSTANT {
    PrefixServerError = '5',
    UnauthorizedError = '401'
  }