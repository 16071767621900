import axios, { AxiosError, AxiosResponse, } from 'axios';
import { SHARE_RIGHT_BASE } from 'configuration';
import { LoginPayLoad, RefreshAccessTokenPayload } from 'models/Requests.model';

class AuthService {
  refreshAccessToken = (payload: RefreshAccessTokenPayload) => {
    return axios({
      url: `${SHARE_RIGHT_BASE.PrefixApi}/user/admin/refresh`,
      method: 'POST',
      data: payload,
    });
  };

  login = (payload: LoginPayLoad) => {
    return axios({
      url: `${SHARE_RIGHT_BASE.PrefixApi}/user/admin/sign_in`,
      method: 'POST',
      data: payload
    });
  };
}

export const authService = new AuthService();