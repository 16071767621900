import axios from 'axios';
import { SHARE_RIGHT_BASE } from 'configuration';
import { AdminGetRequest } from 'models/Requests.model';

class AccountService {
  getAccountInfo = () => {
    return axios({
      url: `${SHARE_RIGHT_BASE.PrefixApi}/user/info`,
      method: 'GET',
    });
  };
  getUserProfilesAdmin = (params: AdminGetRequest | undefined) => {
    return axios({
      url: `${SHARE_RIGHT_BASE.PrefixApi}/user/admin/list_user`,
      method: 'GET',
      params: params
    });
  };

  unbanUserProfileAdmin = (payload: {
    userId: number
  }) => {
    return axios({
      url: `${SHARE_RIGHT_BASE.PrefixApi}/user/unban`,
      method: 'POST',
      data: payload
    })
  }

  banUserProfileAdmin = (payload: {
    userId: number
  }) => {
    return axios({
      url: `${SHARE_RIGHT_BASE.PrefixApi}/user/ban`,
      method: 'POST',
      data: payload
    })
  }
}

export const accountService = new AccountService();
