import { FC, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  AuthConsumer,
} from "contexts/AuthContext";
import {
  CredentialInfo,
} from "models/TransferObject.model";
import {
  ERROR_CONSTANT,
  LOCAL_STORAGE_KEYS,
  TOKEN_STATUS,
} from "models/constant";
import {
  selectCredentialInfo,
} from "redux/reducer";
import routes from "./routes";
import { shrTheme } from "./theme";

const App: FC = () => {
  const allPages = useRoutes(routes);
  const dispatch = useDispatch();
  const authContext = AuthConsumer();
  const credentialInfo = useSelector(selectCredentialInfo) as CredentialInfo;

  const appTheme = shrTheme();

  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
  };

  // Define request and response interceptors
  const onRequestSuccess = (config: any) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AccessToken);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  };

  const onRequestError = (error: any) => {
    const errMessage = error.response?.data || error?.response || error;
    return Promise.reject(errMessage);
  };

  const onResponseSuccess = (response: AxiosResponse) => {
    return Promise.resolve(response.data);
  };

  const onResponseError = (error: AxiosError) => {
    const errorStatus = error.response?.status.toString();
    if (errorStatus?.startsWith(ERROR_CONSTANT.PrefixServerError)) {
      return handleServiceError(error);
    }

    if (errorStatus === ERROR_CONSTANT.UnauthorizedError) {
      return handleUnAuthorizedError(error);
    }

    return handleBuzError(error);
  };

  const serviceErrorMessage = (error: any) => {
    return {
      code: error.response.status,
      message: "Internal Server Error!!!",
    };
  };

  const handleUnAuthorizedError = (error: AxiosError) => {
    if (!error.config || !error.config.url || error.config.url.includes(`/refresh`)) {
      authContext?.logout();
      return Promise.reject(serviceErrorMessage(error));
    }

    const originalRequest = error.config as any;
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.AccessToken);
    if (!accessToken) return Promise.reject(serviceErrorMessage(error));

    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.RefreshToken);
    const tokenStatus = authContext?.getTokenStatus(accessToken) as TOKEN_STATUS;

    switch (tokenStatus) {
      case TOKEN_STATUS.EXPIRED:
        if (!authContext?.refreshInvalidToken({ accessToken: accessToken, refreshToken: refreshToken || undefined })) {
          return Promise.reject(serviceErrorMessage(error));
        }

        axios.defaults.headers.common["Authorization"] = `Bearer ${credentialInfo.accessToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${credentialInfo.accessToken}`;
        return axios(originalRequest);

      case TOKEN_STATUS.INVALID:
        return Promise.reject(serviceErrorMessage(error));
      case TOKEN_STATUS.VALID:
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return axios(originalRequest);
      default:
        return Promise.reject(serviceErrorMessage(error));
    }
  };

  const handleServiceError = (error: AxiosError) => {
    return Promise.reject(serviceErrorMessage(error));
  };

  const handleBuzError = (error: AxiosError) => {
    return Promise.reject(error);
  };

  useEffect(() => {
    const axiosRequestInterceptor = axios.interceptors.request.use(onRequestSuccess, onRequestError);
    const axiosResponseInterceptor = axios.interceptors.response.use(onResponseSuccess, onResponseError);

    return () => {
      axios.interceptors.request.eject(axiosRequestInterceptor);
      axios.interceptors.response.eject(axiosResponseInterceptor);
    };
  }, [authContext]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <Toaster toastOptions={toasterOptions} />
        {allPages}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
