import { Badge, Box, ButtonBase, styled } from "@mui/material";
import FlexBox from "components/FlexBox";
import { H6, Small, Tiny } from "components/Typography";
import SrAvatar from "components/SrAvatar";
import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import PopoverLayout from "./PopoverLayout";
import { AuthConsumer } from "contexts/AuthContext";

import { selectCredentialInfo, setLoading } from "redux/reducer";
import { useSelector } from "react-redux";
import { accountService } from "services/AccountService";

// styled components
const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.light
        : theme.palette.divider,
  },
}));

export interface UserInfo {
  username: string;
  email: string;
  companyAddress: string;
  companyUenNumber: string;
  companyName: string;
  phoneNumber: string;
  status: string;
  password?: string;
  avatar: string;
}

const ProfilePopover: FC = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [adminAccount, setAdminAccount] = useState<AdminAccountInfo>();
  const authContext = AuthConsumer();
  const credentialInfo = useSelector(selectCredentialInfo);

  enum USER_STATUS {
    ACTIVE = 'ACTIVE',
    BAN = 'BAN'
  }

  interface AdminAccountInfo {
    username: string,
    fullname: string,
    phoneNumber: string,
    status: USER_STATUS,
    avatar: string,
  }

  const getAccountInfo = useCallback(async () => {
    setLoading(true);
    const account = (await accountService.getAccountInfo()) as unknown as AdminAccountInfo;
    setAdminAccount(account);
    setLoading(false);
  }, []);

  useEffect(() => {
    getAccountInfo();
  }, [credentialInfo, getAccountInfo])


  return (
    <Fragment>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiBadge-badge": {
              width: 11,
              height: 11,
              right: "7%",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "success.main",
            },
          }}
        >
          <SrAvatar
            src={adminAccount?.avatar || "/static/avatar/001-man.svg"}
            sx={{ width: 50, height: 50, ml: 1 }}
          />
        </Badge>
      </ButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center">
            <SrAvatar
              src={adminAccount?.avatar || "/static/avatar/001-man.svg"}
              sx={{ width: 35, height: 35 }}
            />

            <Box ml={1}>
              <H6>{adminAccount?.username}</H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                {adminAccount?.username}
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
        <Box pt={1}>
          <StyledSmall
            onClick={() => {
              authContext?.logout();
              toast.error("You Logout Successfully");
            }}
          >
            Sign Out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
