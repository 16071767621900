import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CredentialInfo } from 'models/TransferObject.model';

interface AppState {
  initCallInterceptor: boolean;
  loading: boolean;
  registerDialog: boolean;
  verifyEmailDialog: boolean;
  loginDialog: boolean;
  activeStep: number;
  credentialInfo: CredentialInfo | null;
  paymentStepProcessing: number;
  s3PrivateKey: string;
}

// Create a utility function to reset the state
function resetState(state: AppState): AppState {
  return {
    ...state,
    initCallInterceptor: false,
    loading: false,
    registerDialog: false,
    verifyEmailDialog: false,
    loginDialog: false,
    activeStep: 1,
    paymentStepProcessing: 0,
    s3PrivateKey: '',
  };
}

// Init state
const initialState: AppState = resetState({
  initCallInterceptor: false,
  loading: false,
  registerDialog: false,
  verifyEmailDialog: false,
  loginDialog: false,
  activeStep: 1,
  paymentStepProcessing: 0,
  s3PrivateKey: '',
  credentialInfo: null,
});

// reducer callback
const reducers = {
  setInitCallInterceptor(state: AppState, action: PayloadAction<boolean>) {
    state.initCallInterceptor = action.payload;
  },
  setLoading(state: AppState, action: PayloadAction<boolean>) {
    state.loading = action.payload;
  },
  setRegisterDialog(state: AppState, action: PayloadAction<boolean>) {
    state.registerDialog = action.payload;
  },
  setVerifyEmailDialog(state: AppState, action: PayloadAction<boolean>) {
    state.verifyEmailDialog = action.payload;
  },
  setLoginDialog(state: AppState, action: PayloadAction<boolean>) {
    state.loginDialog = action.payload;
  },
  setActiveStep(state: AppState, action: PayloadAction<number>) {
    state.activeStep = action.payload;
  },
  setCredentialInfo(state: AppState, action: PayloadAction<CredentialInfo>) {
    state.credentialInfo = action.payload;
  },
  setPaymentStepProcessing(state: AppState, action: PayloadAction<number>) {
    state.paymentStepProcessing = action.payload;
  },
  resetAppState(state: AppState, action: PayloadAction<any>) {
    if (action.payload === 'resetAppState') {
      return resetState(initialState);
    }
  },
  setS3PrivateKey(state: AppState, action: PayloadAction<string>) {
    state.s3PrivateKey = action.payload;
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers,
});

export const {
  setInitCallInterceptor,
  setLoading,
  setRegisterDialog,
  setVerifyEmailDialog,
  setLoginDialog,
  setActiveStep,
  setCredentialInfo,
  setPaymentStepProcessing,
  resetAppState,
  setS3PrivateKey,
} = appSlice.actions;

export const selectInitCallInterceptor = (state: { app: AppState }) =>
  state.app?.initCallInterceptor;
export const appSelector = (state: { app: AppState }) => state.app;
export const selectLoading = (state: { app: AppState }) => state.app?.loading;
export const selectRegisterDialog = (state: { app: AppState }) => state.app?.registerDialog;
export const selectVerifyEmailDialog = (state: { app: AppState }) => state.app?.verifyEmailDialog;
export const selectLoginDialog = (state: { app: AppState }) => state.app?.loginDialog;
export const selectCredentialInfo = (state: { app: AppState }) => state.app?.credentialInfo;
export const selectS3PrivateKey = (state: { app: AppState }) => state.app?.s3PrivateKey;