import SettingsProvider from "contexts/SettingsContext";
import TitleContextProvider from "contexts/TitleContext";
import "nprogress/nprogress.css";
import ReactDOM from "react-dom";
import "react-image-lightbox/style.css";
import { BrowserRouter } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import App from "./App";
import "./__fakeApi__";
import { Provider } from "react-redux";
import AuthProvider from "contexts/AuthContext";
import store from "redux/store";

ReactDOM.render(
  <SettingsProvider>
    <TitleContextProvider>
      <BrowserRouter>
        <Provider store={store}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Provider>
      </BrowserRouter>
    </TitleContextProvider>
  </SettingsProvider>,
  document.getElementById("root")
);
