export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const SHARE_RIGHT_BASE = {
  PrefixApi: '/api/v1',
};