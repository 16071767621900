import jwtDecode from "jwt-decode";
import { Token } from "models/TransferObject.model";
import { LOCAL_STORAGE_KEYS, TOKEN_STATUS } from "models/constant";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCredentialInfo } from "redux/reducer";
import { authService } from "services/AuthService";

export interface AuthContextValue {
  token?: Token | null;
  saveToken: (data: Token) => void;
  logout: () => void;
  refreshInvalidToken: (token: Token | null) => Promise<boolean>;
  getTokenStatus: (accessToken: string) => TOKEN_STATUS;
}

// Create context
const AuthContext = createContext<AuthContextValue | undefined>(undefined);

function AuthProvider({ children }: any) {
  const [token, setToken] = useState<Token | null>(() => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.AccessToken);
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.RefreshToken);
    return accessToken && refreshToken ? { accessToken, refreshToken } : null;
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saveToken = (data: Token) => {
    if (!data.accessToken || !data.refreshToken) return;

    localStorage.setItem(LOCAL_STORAGE_KEYS.AccessToken, data.accessToken);
    localStorage.setItem(LOCAL_STORAGE_KEYS.RefreshToken, data.refreshToken);
    const { exp, role, userId } = jwtDecode(data?.accessToken) as any;
    dispatch(
      setCredentialInfo({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        userId: userId,
        exp: exp,
        role: role,
      })
    );
    setToken(data);
  };

  const logout = () => {
    const startPath = "/sign-in";
    setToken(null);
    localStorage.clear();
    sessionStorage.clear();
    navigate(startPath, { replace: true });
  };

  const getTokenStatus = (accessToken: string) => {
    if (!accessToken) return TOKEN_STATUS.INVALID;

    const { exp, role, userId } = jwtDecode(accessToken) as any;
    if (Date.now() >= exp * 1000) {
      return TOKEN_STATUS.EXPIRED;
    }
    return TOKEN_STATUS.VALID;
  };

  const refreshInvalidToken = async (token: Token | null) => {
    try {
      if (!token || !token.accessToken) {
        logout();
        return false;
      }

      const tokenStatus = getTokenStatus(token.accessToken);

      switch (tokenStatus) {
        case TOKEN_STATUS.EXPIRED:
          if (token.refreshToken) {
            const refreshToken = (await authService.refreshAccessToken({
              refreshToken: token.refreshToken,
            })) as Token;
            saveToken(refreshToken);
            return true;
          }
          logout();
          return false;
        case TOKEN_STATUS.INVALID:
          logout();
          return false;
        case TOKEN_STATUS.VALID:
          const { exp, role, userId } = jwtDecode(token.accessToken) as any;
          dispatch(
            setCredentialInfo({
              accessToken: token.accessToken,
              refreshToken: token.refreshToken,
              userId: userId,
              exp: exp,
              role: role,
            })
          );
          return true;
      }
    } catch (error) {
      logout();
      return false;
    }
  };

  useEffect(() => {
    refreshInvalidToken(token);
  }, [token?.accessToken, token?.refreshToken]);

  const value = useMemo(
    () => ({
      token: token,
      saveToken: saveToken,
      logout: logout,
      refreshInvalidToken: refreshInvalidToken,
      getTokenStatus: getTokenStatus,
    }),
    [token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

export const AuthConsumer = () => {
  return useContext(AuthContext);
};
